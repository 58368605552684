<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content class="lau-AttributesDialog">
  <div class="attributes-list">
    <form [formGroup]="attributesFormGroup">
      <div *ngFor="let attribute of attributes; let i = index">
        <mat-checkbox class="example-margin" (change)="onChange($event)" [value]="attribute">
          {{ attribute.attributeName }}
        </mat-checkbox>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancelAction()">
    {{ rejectButtonText }}
  </button>
  <button [disabled]="!attributesFormGroup.value.attributes.length" mat-flat-button (click)="confirmAction()">
    {{ confirmButtonText }}
  </button>
</div>
