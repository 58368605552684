<div (focusout)="onFocusOut($event)">
  <ng-container *ngIf="currentCategory === 'Primitive'; else textTemplate">
    <input
      class="lau-CellDataType-input"
      type="text"
      aria-label="Number"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
      #autoInput
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions" [value]="option.typeName">
        {{ option.typeName }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
  <ng-template #textTemplate>
    {{ params.data.dataType }}
  </ng-template>
</div>
