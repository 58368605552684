<div class="dialog-container">
  <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Preview feature</h1>

  <mat-dialog-content>
    <mat-tab-group attributeTab xpo-alternate-tabs class="tab-group">
      <mat-tab class="mat-tab-container attribute-json" label="Contracts">
        <ng-template matTabContent>
          <xpo-card>
            <xpo-card-content class="attribute-json">
              <ng-container *ngIf="feature.contract?.length; else emptyContract">
                <div *ngFor="let contract of feature.contract">
                  <contract-item
                    [feature]="feature"
                    [componentCd]="contract.componentCd"
                    [serviceName]="contract.serviceName"
                  ></contract-item>
                </div>
              </ng-container>
            </xpo-card-content>
          </xpo-card>
        </ng-template>
      </mat-tab>
      <mat-tab class="mat-tab-container" label="Custom Types">
        <ng-template matTabContent>
          <xpo-card>
            <xpo-card-content class="attribute-json">
              <div class="container" *ngIf="typeToDisplay; else emptyType">
                <div class="container-search">
                  <mat-form-field floatLabel="always">
                    <mat-label>Search Term</mat-label>
                    <input matInput (input)="updateSearch($event)" />
                  </mat-form-field>
                </div>
                <div class="container-json">
                  <json-viewer [json]="typeToDisplay" [searchText]="searchText"></json-viewer>
                </div>
              </div>
            </xpo-card-content>
          </xpo-card>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button mat-button (click)="closeDialog()">Close</button>
  </mat-dialog-actions>
</div>

<ng-template #emptyContract>
  <div class="no-content">
    <xpo-empty-state>
      <xpo-empty-state-icon>
        <mat-icon>description</mat-icon>
      </xpo-empty-state-icon>
      <xpo-empty-state-header>This feature doesn't have contracts yet.</xpo-empty-state-header>
    </xpo-empty-state>
  </div>
</ng-template>
<ng-template #emptyType>
  <div class="no-content">
    <xpo-empty-state>
      <xpo-empty-state-icon>
        <mat-icon>description</mat-icon>
      </xpo-empty-state-icon>
      <xpo-empty-state-header>This feature doesn't have types yet.</xpo-empty-state-header>
    </xpo-empty-state>
  </div>
</ng-template>
