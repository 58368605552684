import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ApiTypeAttributeDataTypeCd, ApiTypeCategoryCd, ApiTypeStatusCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'grid-data-type',
  templateUrl: './grid-data-type.component.html',
  styleUrls: ['./grid-data-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'lau-CellDataType',
  },
})
export class GridDataTypeComponent implements OnInit, AfterViewInit {
  params;
  myControl = new UntypedFormControl();
  options: any[];
  filteredOptions: any;
  selectedOption: string;
  typesList: any[] = [];
  currentTypeName: string = '';
  currentCategory: ApiTypeCategoryCd;
  @ViewChildren('autoInput') autoInput;

  agInit(params): void {
    this.params = params;
    this.myControl.setValue(params.value);
    if (params.data.category) {
      this.currentCategory = params.data.category;
      this.getTypesList(params.data.category);
    }
  }

  ngOnInit(): void {
    this.myControl.valueChanges.subscribe((value) => {
      if (this.currentCategory) {
        this.getTypesList(this.currentCategory);
      }
    });
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.autoInput.first.nativeElement.focus();
    });
  }

  private getTypesList(category: ApiTypeCategoryCd) {
    if (category === ApiTypeCategoryCd.PRIMITIVE) {
      const newPrimitivesList = [];
      Object.keys(ApiTypeAttributeDataTypeCd).map((key) => {
        const type = { typeName: ApiTypeAttributeDataTypeCd[key] };
        newPrimitivesList.push(type);
      });
      this.options = newPrimitivesList;
      this.filteredOptions = this._filter(this.myControl.value);
    }
  }

  onOptionSelected(e): void {
    if (this.currentCategory === ApiTypeCategoryCd.PRIMITIVE) {
      this.selectedOption = e.option.value;
    }
  }

  isCancelAfterEnd(): boolean {
    if (!this.selectedOption) {
      return true;
    }

    return false;
  }

  getValue(): any {
    return this.myControl.value;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.typeName.toLowerCase().includes(filterValue));
  }

  onFocusOut(e) {
    if (!e.relatedTarget) {
      this.params.api.stopEditing();
    }
  }
}
