import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppConfigsService } from '../../arsenal-components/services/app-configs.service';
import { AppRoutes } from '../enums/app-routes.enum';
import { MatDialog } from '@angular/material/dialog';
import { NotAuthorizedComponent } from '../../dialogs/not-authorized/not-authorized.component';

@Injectable()
export class MaintAdminsGuard implements CanActivate {
  constructor(private appConfigsService: AppConfigsService, private router: Router, private dialog: MatDialog) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.appConfigsService.loggedInComplete.pipe(
      filter((complete) => complete),
      mergeMap((_) => {
        return this.appConfigsService.isUserMaintAdmin$.pipe(
          map((isUserMaintAdmin) => {
            if (isUserMaintAdmin) {
              return true;
            }
            this.dialog.open(NotAuthorizedComponent, {disableClose: true})
            return false;
          })
        );
      })
    );
  }
}
