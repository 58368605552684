import { Injectable } from '@angular/core';
import { ApiMetadataApiService } from '@xpo-ltl/sdk-apimetadata';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/**
 * Provides access to the currently logged-in User
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlLoggedInUserService {
  private isLoggedIn: boolean;

  /**
   * emits when the user is logged in
   */
  user$ = new BehaviorSubject<User>(null);

  constructor(private apiMetadataApiService: ApiMetadataApiService) {}

  get loggedInUser$(): Observable<User> {
    if (this.user$.getValue()) {
      return this.user$.asObservable();
    } else {
      return this.apiMetadataApiService.loggedInUser({ loadingOverlayEnabled: false }).pipe(
        tap((user) => this.user$.next(user)),
        map((user) => user)
      );
    }
  }
}
