<button
  *ngIf="!params.value && !isPrimitiveOrEnum && params.data.category !== '' && params.data.type !== ''"
  class="lau-GridActions-iconButton--link"
  (click)="openDialog()"
  mat-icon-button
>
  <a class="lau-GridActions-iconLink">
    {{ cellLinkText }}
  </a>
</button>
<span *ngIf="params.value && !isPrimitiveOrEnum">{{ params.value }}</span>
