export enum Actions {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW = 'view',
  IMPLEMENTED = 'implemented',
  CANCEL = 'cancel',
  REVERT = 'revert',
  ADD_LABEL = 'Add',
  EDIT_LABEL = 'Edit',
  DELETE_LABEL = 'Delete',
  IMPLEMENTED_LABEL = 'Implemented',
  CANCEL_LABEL = 'Cancel',
  REVERT_LABEL = 'Revert',
  VIEW_CONTRACTS_LABEL = 'View/Diff Contracts',
  VIEW_IMPLEMENTED_CONTRACTS_LABEL = 'Implemented Contracts',
  VIEW_IMPLEMENTED_CUSTOM_TYPES_LABEL = 'Implemented Custom Types',
  CLONE = 'Clone',
  MOVE_ATTRIBUTE_TYPE = 'type',
  MOVE_ATTRIBUTE_TYPE_LABEL = 'Move Attribute Type',
  NEW_FEATURE = 'New Feature',
  FEATURE = 'feature',
  SWAGGER = 'swagger',
  SWAGGER_TOOLTIP = 'Swagger UI',
}
