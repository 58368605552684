import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { AppRoutes } from '../enums/app-routes.enum';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';

@Injectable()
export class IsNotProdGuard implements CanActivate {
  constructor(private configManagerService: ConfigManagerService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const isProd = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    if (!isProd) {
      return true;
    }
    return this.router.parseUrl(`/${AppRoutes.COMPONENTS_PAGE}`);
  }
}
