<mat-expansion-panel *ngIf="contract" class="contract" [expanded]="panelExpanded">
  <mat-expansion-panel-header style="cursor: pointer">
    <mat-panel-title>
      <contract-header [contract]="contract"></contract-header>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <span class="status"><b>status: &nbsp;</b>{{ contract.statusCd.toUpperCase() }}</span>
  <div class="description">
    <pre class="description-text">{{ contract.serviceDescription }}</pre>
  </div>
  <xpo-card *ngIf="hasParameters">
    <xpo-card-header>
      <xpo-card-title>Parameters</xpo-card-title>
    </xpo-card-header>
    <xpo-card-content>
      <div *ngFor="let type of contract.contractType">
        <div *ngIf="type.contractCategoryCd !== 'Response' && type.contractCategoryCd !== 'Request'" class="type">
          <div *ngFor="let attribute of type.contractTypeAttribute" class="attribute">
            <div class="attribute-info">
              <span class="attribute-name">{{ attribute.cntrctTypAttrName }}</span>
              <span *ngIf="convertNumber(attribute.minimumNbr) > 0" class="attribute-required"
                >&nbsp;&nbsp;* required</span
              ><br />
              <span class="attribute-data">{{
                attribute.parentType ? attribute.parentType.typeName : attribute.dataType
              }}</span
              ><br />
              <span class="attribute-type">({{ type.contractCategoryCd.toLowerCase() }})</span>
            </div>
            <div class="attribute-description">
              {{ attribute.description }}
            </div>
          </div>
        </div>
      </div>
    </xpo-card-content>
  </xpo-card>
  <xpo-card *ngIf="!!request">
    <xpo-card-header>
      <xpo-card-title>Request</xpo-card-title>
    </xpo-card-header>
    <xpo-card-content>
      <div class="attribute">
        <div class="attribute-info">
          <span class="attribute-name">{{ request.contractTypeName }}</span
          ><br />
          <span class="attribute-type">(body)</span>
        </div>
        <div class="attribute-description attribute-json">
          <pre>{{ requestJson.data | json }}</pre>
        </div>
      </div>
    </xpo-card-content>
  </xpo-card>
  <xpo-card *ngIf="!!response">
    <xpo-card-header>
      <xpo-card-title>Response</xpo-card-title>
    </xpo-card-header>
    <xpo-card-content>
      <div class="attribute">
        <div class="attribute-info">
          <span class="attribute-name">{{ response.contractTypeName }}</span
          ><br />
          <span class="attribute-type">(response)</span>
        </div>
        <div class="attribute-description attribute-json">
          <pre>{{ responseJson.data | json }}</pre>
        </div>
      </div>
    </xpo-card-content>
  </xpo-card>
</mat-expansion-panel>
