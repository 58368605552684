export enum AppRoutes {
  COMPONENTS_PAGE = 'api-components',
  CONTRACTS_PAGE = 'contracts',
  IMPLEMENTED_CONTRACTS_PAGE = 'implemented-contracts',
  IMPLEMENTED_CUSTOM_TYPES_PAGE = 'implemented-custom-types',
  CONTRACTS_DETAILS_PAGE = 'contract',
  FEATURES_PAGE = 'features',
  FEATURES_DETAILS_PAGE = 'feature',
  FEATURES_LIST_PAGE = 'list',
  TYPES_PAGE = 'types',
  TYPE_DETAILS_PAGE = 'type',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  AEM_PAGE = 'aem',
  AUTH_PAGE = 'auth',
  SWAGGER = 'swagger',
  API_STORE = 'api-store',
  VIEW = 'view',
}
