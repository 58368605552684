<div class="lau-Description" (focusout)="onFocusOut($event)">
  <input
    specialIsAlphaNumeric
    [regexStr]="'^[A-Za-z0-9\\s-/()_;\&#39;\:\&#34;#,.//*]+$'"
    [validateCP]="true"
    [allow]="true"
    tabindex="-1"
    maxlength="100"
    #descriptionInput
    type="text"
    class="lau-DescriptionInput"
    matInput
    [formControl]="description"
    (keydown.shift.tab)="$event.preventDefault()"
  />
</div>
