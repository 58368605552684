import { Injectable } from '@angular/core';
import {
  ApiMetadataApiService,
  CreateCustomTypeRqst,
  EnumType,
  EnumValue,
  GetTypeByFeatureIdAndNameResp,
  ListEnumsQuery,
  ListEnumValuesPath,
  ListTypesByComponentCdQuery,
  ListTypesByComponentCdResp,
  ListTypesByFeatureIdResp,
  Type,
  UpdateCustomTypeResp,
} from '@xpo-ltl/sdk-apimetadata';
import { ApiTypeCategoryCd, ApiTypeStatusCd, ListInfo } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TypesService {
  constructor(private apiMetadataApiService: ApiMetadataApiService) {}

  getListTypes(
    componentCd: string,
    categoryCd: ApiTypeCategoryCd,
    statusCd?: ApiTypeStatusCd,
    featureId?: number,
    listInfo?: ListInfo,
    serviceName?: string,
    typeName?: string
  ): Observable<Type[]> {
    return this.apiMetadataApiService
      .listTypesByComponentCd({ componentCd }, {
        categoryCd,
        statusCds: [statusCd],
        listInfo,
        featureId,
        typeName,
        serviceName,
      } as ListTypesByComponentCdQuery)
      .pipe(map((resp: ListTypesByComponentCdResp) => resp.types));
  }

  getListTypesByFeature(featureId: number, categoryCd: ApiTypeCategoryCd): Observable<Type[]> {
    return this.apiMetadataApiService
      .listTypesByFeatureId({ featureId: featureId }, { categoryCd: categoryCd, listInfo: null })
      .pipe(map((resp: ListTypesByFeatureIdResp) => resp.types));
  }

  getTypeDetails(featureId: number, typeName: string): Observable<Type> {
    return this.apiMetadataApiService
      .getTypeByFeatureIdAndName({ featureId, typeName })
      .pipe(map((resp: GetTypeByFeatureIdAndNameResp) => resp.type));
  }

  deleteType(componentCd: string, featureId: number, typeName: string): Observable<void> {
    return this.apiMetadataApiService.deleteCustomType({ componentCd, featureId, typeName });
  }

  updateType(type: Type, newName: string): Observable<Type> {
    return this.apiMetadataApiService
      .updateCustomType({ type, newName })
      .pipe(map((resp: UpdateCustomTypeResp) => resp.type));
  }

  addType(type: Type, isModified: boolean = false, oldTypeName?: string, oldFeatureId?: number): Observable<Type> {
    return this.apiMetadataApiService
      .createCustomType({ type, oldTypeName, oldFeatureId } as CreateCustomTypeRqst, { update: isModified })
      .pipe(map((resp) => resp.type));
  }

  getEnumTypes(): Observable<EnumType[]> {
    const req = new ListEnumsQuery();
    return this.apiMetadataApiService.listEnums(req).pipe(map((resp) => resp.enums));
  }

  getEnumInfo(enumNameTxt: string, loading = false): Observable<EnumValue[]> {
    const req = new ListEnumValuesPath();
    req.enumName = enumNameTxt;
    return this.apiMetadataApiService.listEnumValues(req).pipe(map((resp) => resp.enumValues));
  }
}
