import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { XpoFiltersModule } from '@xpo-ltl/ngx-board/core';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { OnlynumberDirective } from '../arsenal-components/directives/onlyNumber.directive';
import { SpecialCharacterDirective } from '../arsenal-components/directives/specialCharacter.directive';
import { GridContractAttributesComponent } from '../arsenal-components/modules/features/components/contract-details/req-resp-list/renderers/grid-contract-attributes/grid-contract-attributes.component';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ContractBodyComponent } from './components/contract-body/contract-body.component';
import { ContractHeaderComponent } from './components/contract-header/contract-header.component';
import { ContractItemComponent } from './components/contract-item/contract-item.component';
import { GridActionsComponent } from './components/grid-actions/grid-actions.component';
import { GridAttributesComponent } from './components/grid-attributes/grid-attributes.component';
import { GridAutocompleteComponent } from './components/grid-autocomplete/grid-autocomplete.component';
import { GridCategoryComponent } from './components/grid-category/grid-category.component';
import { GridDataTypeComponent } from './components/grid-data-type/grid-data-type.component';
import { GridDescriptionComponent } from './components/grid-description/grid-description.component';
import { GridFaultMsgComponent } from './components/grid-faultmsg/grid-faultmsg.component';
import { GridLinksComponent } from './components/grid-links/grid-links.component';
import { GridNamedComponent } from './components/grid-named/grid-named.component';
import { GridNumericEditorComponent } from './components/grid-numeric-editor/grid-numeric-editor.component';
import { GridOcurrencesComponent } from './components/grid-ocurrences/grid-ocurrences.component';
import { GridSeverityComponent } from './components/grid-severity/grid-severity.component';
import { MessageTooltipComponent } from './components/grid-tooltip/message-tooltip.component';
import { GridColumnCheckIconRendererComponent } from './components/grid-yes-no-icon/grid-column-check-icon-renderer.component';
import { HelperMenuComponent } from './components/helper-menu/helper-menu.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { XpoSelectOptGroupFilterComponent } from './components/select-filter/xpo-select-opt-group-filter';
import { XpoSelectOptGroupComponent } from './components/select-filter/xpo-select-opt-group-filter/select-opt-group/select-opt-group.component';
import { NoSpaceOnlyDirective } from './directives/no-space-only.directive';
import { HighlightPipe } from './pipes/highlight.pipe';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    GridLinksComponent,
    GridActionsComponent,
    BreadcrumbsComponent,
    GridAutocompleteComponent,
    GridAttributesComponent,
    GridOcurrencesComponent,
    GridCategoryComponent,
    GridNumericEditorComponent,
    GridContractAttributesComponent,
    GridNamedComponent,
    GridDataTypeComponent,
    GridSeverityComponent,
    GridFaultMsgComponent,
    GridDescriptionComponent,
    SpecialCharacterDirective,
    OnlynumberDirective,
    ContractHeaderComponent,
    ContractBodyComponent,
    NoSpaceOnlyDirective,
    MessageTooltipComponent,
    ContractItemComponent,
    HelperMenuComponent,
    InfoDialogComponent,
    GridColumnCheckIconRendererComponent,
    XpoSelectOptGroupFilterComponent,
    XpoSelectOptGroupComponent,
    JsonViewerComponent,
    // Pipes/Directives
    HighlightPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    XpoButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    XpoCardModule,
    XpoStatusIndicatorModule,
    MatExpansionModule,
    MatInputModule,
    MatMenuModule,
    XpoDialogModule,
    XpoIconModule,
    XpoFiltersModule,
    MatRadioModule,
    MatChipsModule,
    // Pipes/Directives
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    GridLinksComponent,
    GridActionsComponent,
    BreadcrumbsComponent,
    GridNumericEditorComponent,
    SpecialCharacterDirective,
    OnlynumberDirective,
    ContractHeaderComponent,
    ContractBodyComponent,
    NoSpaceOnlyDirective,
    ContractItemComponent,
    HelperMenuComponent,
    InfoDialogComponent,
    GridColumnCheckIconRendererComponent,
    JsonViewerComponent,
    // Pipes/Directives
    HighlightPipe,
  ],
})
export class SharedModule {}
