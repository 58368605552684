import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { MaintAdminsGuard } from './shared/guards/maint-admins.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.COMPONENTS_PAGE,
    data: { breadcrumb: 'API Components' },
    loadChildren: () => import('./arsenal-components/arsenal-components.module').then((m) => m.ArsenalComponentsModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, MaintAdminsGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: AppRoutes.AEM_PAGE,
    data: { breadcrumb: 'AEM Components' },
    loadChildren: () => import('./aem/aem.module').then((m) => m.AemModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, MaintAdminsGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    redirectTo: AppRoutes.COMPONENTS_PAGE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routerDefinitions, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
