import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-arsenal-confirm-dialog',
  templateUrl: './arsenal-confirm-dialog.component.html',
  styleUrls: ['./arsenal-confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArsenalConfirmDialogComponent {
  confirmButtonText: string;
  rejectButtonText: string;
  rejectButtonColor: ThemePalette;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ArsenalConfirmDialogComponent>
  ) {
    this.confirmButtonText = data.confirmButtonText || 'Confirm';
    this.rejectButtonText = 'Cancel';
    this.rejectButtonColor = 'warn';
  }

  confirmAction(): void {
    this.dialogRef.close(true);
  }

  cancelAction(): void {
    this.dialogRef.close(false);
  }
}
