import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from '@xpo-ltl/sdk-apimetadata';
import { ApiTypeCategoryCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-preview-feature-dialog',
  templateUrl: './preview-feature-dialog.component.html',
  styleUrls: ['./preview-feature-dialog.component.scss'],
})
export class PreviewFeatureDialogComponent {
  typeToDisplay: any;
  feature: Feature;
  loaded: boolean;
  searchText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreviewFeatureDialogComponent>,
    public dialog: MatDialog
  ) {
    this.feature = this.data.feature;
    this.loaded = false;
    this.buildDataToDisplay();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private buildDataToDisplay(): void {
    if (this.feature.type) {
      const obj = {};

      this.feature.type.forEach((type) => {
        obj[type.typeName] = {};
        const children = [];
        type.typeAttribute?.forEach((attribute) => {
          if (!attribute.extensionAttributeName) {
            if (attribute.extensionParentTypeName) {
              obj[type.typeName][attribute.attributeName] = {};
            } else {
              obj[type.typeName][attribute.attributeName] = this.determineAttributeTypeName(attribute);
            }
          } else {
            children.push(attribute);
          }
        });

        children.forEach((attribute) => {
          obj[type.typeName][attribute.extensionAttributeName][attribute.attributeName] =
            this.determineAttributeTypeName(attribute);
        });
      });
      this.typeToDisplay = obj;
    }
  }

  private determineAttributeTypeName(attribute): string {
    const isObject = attribute.dataType === 'object';
    const isEnum = attribute.categoryCd === ApiTypeCategoryCd.ENUM && attribute.enumType?.enumNameTxt;

    if (isEnum) {
      return attribute.enumType.enumNameTxt;
    } else if (isObject) {
      if (attribute.extensionParentTypeName) {
        return `Extended from: ${attribute.extensionParentTypeName}`;
      }
      if (attribute.parentType?.typeName) {
        return attribute.parentType.typeName;
      }
    }
    return attribute.dataType;
  }

  updateSearch(e) {
    this.searchText = e.target.value;
  }
}
