<ng-container *ngIf="links$ | async">
  <mat-icon
    class="helper-icon"
    matTooltip="Documentation"
    matTooltipPosition="left"
    aria-tooltip="Icon helper for documentation about the application"
    [matMenuTriggerFor]="linkItemMenu"
    >help
  </mat-icon>
  <mat-menu #linkItemMenu="matMenu">
    <ng-container *ngFor="let link of links$ | async">
      <a mat-menu-item href="{{ link.url }}" target="_blank">{{ link.label }}</a>
    </ng-container>
  </mat-menu>
</ng-container>
