import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ArsenalConfirmDialogComponent } from '../../../dialogs/arsenal-confirm-dialog/arsenal-confirm-dialog.component';
import { Actions } from '../../../enums/actions';
import { GridAction } from '../../models/grid-action';
import { ConstantsService } from '../../services/constants/constants.service';

@Component({
  selector: 'grid-actions',
  templateUrl: './grid-actions.component.html',
  styleUrls: ['./grid-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'lau-GridActions',
  },
})
export class GridActionsComponent implements ICellRendererAngularComp {
  params: any;
  gridActions: GridAction[];

  constructor(
    public dialog: MatDialog,
    private snackbar: XpoSnackBar,
    private titleCasePipe: TitleCasePipe,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  refresh(): boolean {
    return false;
  }

  agInit(params) {
    this.gridActions = params.customActions || null;
    this.registerCustomIcons(this.gridActions);
  }

  callAction(action: Function, actionType: Actions, actionParams?: any): void {
    if (actionType === Actions.DELETE) {
      this.showConfirmation(action, actionParams, actionType);
    } else {
      action({ actionParams });
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private showConfirmation(action: Function, actionParams: any, actionType: string) {
    const attribute = actionParams.elementType || actionParams.paramName;
    const isFeature = attribute === Actions.FEATURE;
    const isAttribute = attribute !== Actions.FEATURE && attribute !== 'contract' && attribute !== 'type';
    const dialogRef = this.dialog.open(ArsenalConfirmDialogComponent, {
      width: ConstantsService.confirmDialogWidth,
      data: {
        title: `${this.titleCasePipe.transform(actionType)} ${this.capitalizeFirstLetter(attribute)} ${
          isAttribute ? 'Attribute' : ''
        }`,
        message: actionParams.elementType
          ? `You are about to ${actionType} the following ${attribute}${isAttribute ? ' attribute' : ''}${
              actionParams.featureId && !isFeature ? ' from the feature' : ''
            }: ${actionParams[actionParams.paramName] || ''}.`
          : `You are about to ${actionType} '${actionParams.paramName}' ${isAttribute ? 'Attribute' : ''}.`,
        confirmButtonText: this.titleCasePipe.transform(actionType),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action({ actionParams });
      }
    });
  }

  private registerCustomIcons(gridActions: GridAction[]) {
    this.gridActions.forEach((action) => {
      this.matIconRegistry.addSvgIcon(
        action.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../../assets/images/${action.icon}.svg`)
      );
    });
  }
}
