<div class="lau-FaultMsg" (focusout)="onFocusOut($event)">
  <input
    specialIsAlphaNumeric
    tabindex="-1"
    class="lau-FaultMsgInput"
    #faultMsgInput
    maxlength="50"
    type="text"
    matInput
    [formControl]="faultMsg"
    (keydown.shift.tab)="$event.preventDefault()"
  />
</div>
