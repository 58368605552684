export enum BreadcrumbEnum {
  ACTION = 'action',
  BREADCRUMB = 'breadcrumb',
  FEATURE = 'Features',
  CONTRACT = 'Contract',
  MESSAGE = 'Messages',
  SERVICE_NAME = 'serviceName',
  APP_NAME = 'appName',
  API_STORE = 'api-store',
}
