<div class="dialog-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>

  <mat-dialog-content>
    <div class="lau-ConfirmationMessage">
      <mat-icon class="lau-ConfirmationIcon">warning</mat-icon>{{ data.message }}
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [color]="rejectButtonColor" mat-flat-button (click)="confirmAction()">
      {{ confirmButtonText }}
    </button>
    <button mat-button (click)="cancelAction()">
      {{ rejectButtonText }}
    </button>
  </mat-dialog-actions>
</div>
