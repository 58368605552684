<section class="json-viewer" [ngClass]="{ parent: parent }">
  <section *ngFor="let segment of segments" [ngClass]="['segment', 'segment-type-' + segment.type]">
    <section
      (click)="toggle(segment)"
      [ngClass]="{
        'segment-main': true,
        expandable: isExpandable(segment),
        expanded: segment.expanded
      }"
    >
      <div *ngIf="isExpandable(segment)" class="toggler"></div>
      <span class="segment-key" [innerHTML]="segment.key | highlight: searchText"></span>
      <span class="segment-separator">: </span>
      <span
        *ngIf="!segment.expanded || !isExpandable(segment)"
        class="segment-value"
        [innerHTML]="segment.description | highlight: searchText"
      ></span>
    </section>
    <section *ngIf="segment.expanded && isExpandable(segment)" class="children">
      <json-viewer
        [parent]="false"
        [json]="segment.value"
        [searchText]="searchText"
        [expanded]="expanded"
        [depth]="depth"
        [currentDepth]="currentDepth"
      ></json-viewer>
    </section>
  </section>
</section>
