import { XpoAdvancedSelectComponentOption } from '@xpo-ltl/ngx-board/core';

export class SelectGroupingModel {
  readonly name: string;
  readonly data: XpoAdvancedSelectComponentOption[] | string[];

  constructor(name: string, data: XpoAdvancedSelectComponentOption[] | string[]) {
    this.name = name;
    this.data = data;
  }
}
