<div class="dialog-container">
  <h1 mat-dialog-title>
    Information
  </h1>

  <mat-dialog-content>
    <div class="lau-InfoDialog">
      <h3>
        {{ data.infoText }}
      </h3>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <xpo-button-group>
      <button mat-flat-button (click)="ok()">OK</button>
    </xpo-button-group>
  </mat-dialog-actions>
</div>
