<ng-container *ngIf="currentCategory !== 'Enum' && currentCategory !== 'Primitive'; else enumPrimitiveTemplate">
  <input
    #autoInput
    specialIsAlphaNumeric
    class="lau-CellAutocomplete-input"
    type="text"
    aria-label="Number"
    matInput
    [formControl]="myControl"
    (keydown.shift.tab)="$event.preventDefault()"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    <mat-option
      matTooltip="{{ option.typeName }}"
      matTooltipClass="option-tooltip"
      *ngFor="let option of filteredOptions"
      [value]="option.typeName"
    >
      {{ option.typeName }}
    </mat-option>
  </mat-autocomplete>
</ng-container>
<ng-template #enumPrimitiveTemplate>
  <ng-template *ngIf="currentCategory === 'Enum'; then enumTemplate"> </ng-template>
  <ng-template #enumTemplate>
    <input
      #autoInput
      specialIsAlphaNumeric
      class="lau-CellAutocomplete-input"
      type="text"
      aria-label="Number"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
      (keydown.shift.tab)="$event.preventDefault()"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option matTooltip="{{ option.enumNameTxt }}" *ngFor="let option of filteredOptions" [value]="option">
        {{ option.enumNameTxt }}
      </mat-option>
    </mat-autocomplete>
  </ng-template>
</ng-template>
