<div *ngIf="hasSearchBar" class="xpo-SelectOptGroup-searchContainer">
  <mat-form-field class="xpo-SelectOptGroup-search ngx-FormField--inline">
    <input
      autocomplete="off"
      #searchBox
      matInput
      type="text"
      placeholder="Search"
      (input)="filterOptions($event.target['value'])"
    />
    <mat-icon matSuffix class="xpo-SelectOptGroup-search-icon">search</mat-icon>
  </mat-form-field>
</div>

<mat-radio-group
  *ngIf="isGrouping; else flatMode"
  class="xpo-FilterContainer-optionsContainer"
  [ngModel]="selectedValue"
  (ngModelChange)="onOptionSelected($event)"
>
  <div *ngFor="let group of filteredOptions">
    <div class="option-label" *ngIf="checkIfGroupRegionIsDisplayed(group['data'])">
      <mat-chip xpoChipColor="#e8e8e8">{{ group['name'] }}</mat-chip>
    </div>
    <ng-container *ngFor="let data of group['data']">
      <mat-radio-button *ngIf="!data.hidden" class="xpo-FilterContainer-option" [value]="data">
        {{ data.value }}
      </mat-radio-button>
    </ng-container>
  </div>
</mat-radio-group>

<ng-template #flatMode>
  <mat-radio-group
    class="xpo-FilterContainer-optionsContainer"
    [ngModel]="selectedValue"
    (ngModelChange)="onOptionSelected($event)"
  >
    <div *ngFor="let option of filteredOptions">
      <mat-radio-button *ngIf="!option['hidden']" class="xpo-FilterContainer-option" [value]="option">
        {{ option['label'] }}
      </mat-radio-button>
    </div>
  </mat-radio-group>
</ng-template>
