<ng-container *ngFor="let action of gridActions">
  <button
    tabindex="-1"
    [matTooltip]="action.tooltip"
    matTooltipPosition="above"
    *ngIf="action.action"
    class="lau-GridActions-iconButton--link"
    (click)="callAction(action.action, action.actionType, action.actionParams)"
    mat-icon-button
    [disabled]="action.disabled"
  >
    <mat-icon>{{ action.icon }}</mat-icon>
  </button>
  <button
    [matTooltip]="action.tooltip"
    matTooltipPosition="above"
    *ngIf="!action.action && action.actionUrl && !action.custom"
    class="lau-GridActions-iconButton--link"
    mat-icon-button
    [disabled]="action.disabled"
  >
    <a
      *ngIf="!action.disabled; else actionIcon"
      class="lau-GridActions-iconLink"
      [routerLink]="[action.actionUrl]"
      tabindex="-1"
    >
      <mat-icon>{{ action.icon }}</mat-icon>
    </a>
    <ng-template #actionIcon>
      <mat-icon tabindex="-1">{{ action.icon }}</mat-icon>
    </ng-template>
  </button>
  <button
    [matTooltip]="action.tooltip"
    matTooltipPosition="above"
    *ngIf="!action.action && action.actionUrl && action.custom"
    class="lau-GridActions-iconButton--link"
    mat-icon-button
    [disabled]="action.disabled"
  >
    <a
      *ngIf="!action.disabled; else actionCustomIcon"
      class="lau-GridActions-iconLink"
      [routerLink]="[action.actionUrl]"
      tabindex="-1"
    >
      <mat-icon svgIcon="{{ action.icon }}"></mat-icon>
    </a>
    <ng-template #actionCustomIcon>
      <mat-icon tabindex="-1" svgIcon="{{ action.icon }}"></mat-icon>
    </ng-template>
  </button>
</ng-container>
