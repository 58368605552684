import { AfterViewInit, Component, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EnumType, Type } from '@xpo-ltl/sdk-apimetadata';
import { ApiTypeCategoryCd, ApiTypeStatusCd } from '@xpo-ltl/sdk-common';
import { TypesService } from '../../../arsenal-components/services/types.service';
import { StateService } from '../../services/state/state.service';

@Component({
  selector: 'grid-autocomplete',
  templateUrl: './grid-autocomplete.component.html',
  styleUrls: ['./grid-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'lau-CellAutocomplete',
  },
})
export class GridAutocompleteComponent implements OnInit, AfterViewInit {
  params;
  featureId: number;
  myControl = new UntypedFormControl();
  options: any[];
  filteredOptions: any;
  selectedOption: string;
  componentCd: string;
  typesList: any[] = [];
  currentTypeName: string = '';
  currentCategory: ApiTypeCategoryCd;
  customAction;

  @ViewChildren('autoInput') autoInput;

  constructor(private typesService: TypesService, private stateService: StateService) {
    this.stateService.getCurrentComponentCd().subscribe((componentCd) => {
      this.componentCd = componentCd;
    });
  }

  agInit(params): void {
    this.params = params;
    this.customAction = params.customAction || params.values.customAction;
    const value = params.value || '';
    this.myControl.setValue(value);
    this.currentTypeName = '';
    if (params.data.category) {
      this.currentCategory = params.data.category;
      this.getTypesList(params.data.category);
    }
  }

  ngOnInit(): void {
    this.myControl.valueChanges.subscribe((value) => {
      if (this.currentCategory !== ApiTypeCategoryCd.ENUM) {
        this.filteredOptions = this._filter(value);
      } else {
        this.filteredOptions = this._filterEnum(value);
      }
    });
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.autoInput.first.nativeElement.focus();
    });
  }

  private getTypesList(category: ApiTypeCategoryCd) {
    const listData = this.stateService.getCachedTypes(this.componentCd, category);
    switch (category) {
      case ApiTypeCategoryCd.GENERATED:
      case ApiTypeCategoryCd.CUSTOM:
        if (listData && listData.length) {
          this.options = listData.sort((a, b) => (a.typeName > b.typeName ? 1 : -1));
          this.filteredOptions = this._filter(this.myControl.value);
        } else {
          this.fetchListTypes(category);
        }
        break;
      case ApiTypeCategoryCd.ENUM:
        if (listData && listData.length) {
          this.options = listData.sort((a, b) => (a.enumNameTxt > b.enumNameTxt ? 1 : -1));
          this.filteredOptions = this._filterEnum(this.myControl.value);
        } else {
          this.fetchEnumTypes();
        }
        break;
    }
  }

  fetchEnumTypes() {
    this.typesService.getEnumTypes().subscribe((result) => {
      this.options = result;
      this.options.sort((a, b) => {
        const typeA = a.enumNameTxt.toUpperCase();
        const typeB = b.enumNameTxt.toUpperCase();
        return typeA < typeB ? -1 : typeA > typeB ? 1 : 0;
      });
      this.filteredOptions = this._filterEnum(this.myControl.value);
      this.stateService.setCachedTypes(this.componentCd, ApiTypeCategoryCd.ENUM, this.options, false);
    });
  }

  fetchListTypes(category: ApiTypeCategoryCd) {
    if (category === ApiTypeCategoryCd.CUSTOM) {
      this.stateService.getCurrentFeature().subscribe((feature) => {
        this.featureId = feature.featureId;
      });
    }
    this.typesService
      .getListTypes(this.componentCd, category, ApiTypeStatusCd.IMPLEMENTED, this.featureId)
      .subscribe((result) => {
        this.options = this.filterSameType(result);
        this.options.sort((a, b) => {
          const typeA = a.typeName.toUpperCase();
          const typeB = b.typeName.toUpperCase();
          return typeA < typeB ? -1 : typeA > typeB ? 1 : 0;
        });
        this.filteredOptions = this._filter(this.myControl.value);
        this.stateService.setCachedTypes(this.componentCd, category, this.options, false);
      });
  }

  private filterSameType(list: Type[]) {
    let newList = [...list];
    if (this.currentTypeName !== '') {
      newList = list.filter((type) => type.typeName !== this.currentTypeName);
    }
    return newList;
  }

  onOptionSelected(e): void {
    if (this.currentCategory !== ApiTypeCategoryCd.ENUM) {
      this.selectedOption = e.option.value;
    } else {
      this.customAction({ index: this.params.rowIndex, enum: e.option.value });
      this.selectedOption = e.option.value.enumNameTxt;
      this.myControl.setValue(e.option.value.enumNameTxt);
    }

    this.params.api.stopEditing();
  }

  isCancelAfterEnd(): boolean {
    if (!this.selectedOption) {
      return true;
    }

    return false;
  }

  getValue(): any {
    return this.myControl.value;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.typeName.toLowerCase().includes(filterValue));
  }

  private _filterEnum(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.enumNameTxt.toLowerCase().includes(filterValue));
  }
}
