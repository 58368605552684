<h1 mat-dialog-title>Not Authorized</h1>
<div mat-dialog-content>
  <div class="xpo-NotAuthorized-content">
    <div>
      <p>We're sorry, you are not authorized to use this application.</p>
      <p>Please contact LTL Service Governance</p>
      <br />
      <ul>
        <li>
          <mat-icon>email</mat-icon>
          <a href="mailto:LTLITServiceGovernance@xpo.com">#LTL IT Service Governance</a>
        </li>
      </ul>
    </div>
  </div>
</div>
