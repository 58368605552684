import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigsService {
  isUserMaintAdmin = new BehaviorSubject<boolean>(false);

  loggedInComplete = new BehaviorSubject<boolean>(false);

  constructor() {}

  get isUserMaintAdmin$() {
    return this.isUserMaintAdmin.asObservable();
  }

  get loggedInComplete$() {
    return this.loggedInComplete.asObservable();
  }
}
