import { AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'grid-category',
  templateUrl: './grid-category.component.html',
  styleUrls: ['./grid-category.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'lau-CellCategory',
  },
})
export class GridCategoryComponent implements AfterViewInit {
  categories: string[];
  params: any;
  myControl = new UntypedFormControl();

  @ViewChild('categoriesList') selectRef: MatSelect;

  agInit(params): void {
    this.params = params;
    this.categories = params.categories;
    this.myControl.setValue(params.value);
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.selectRef.focus();
      this.selectRef.open();
    });
  }

  onFocusOut(e) {
    if (!e.relatedTarget) {
      this.params.api.stopEditing();
    }
  }

  onCategorySelected(e) {
    this.params.api.stopEditing();
  }

  getValue(): any {
    return this.myControl.value;
  }
}
