import { Component } from '@angular/core';
import { XpoFilterComponentBase, XpoFilterCriteria } from '@xpo-ltl/ngx-board/core';
import { XpoSelectOptGroupFilter } from './select-opt-group-filter';

@Component({
  selector: 'xpo-select-opt-group-filter',
  templateUrl: './select-opt-group-filter.component.html',
  styleUrls: ['./select-opt-group-filter.component.scss'],
})
export class XpoSelectOptGroupFilterComponent extends XpoFilterComponentBase<XpoSelectOptGroupFilter> {
  selection: string[] | string | any;

  constructor() {
    super();
  }

  optionsSelected(fieldValues: string[]): void {
    this.selection = fieldValues;
    this.storeCriteria(fieldValues);
  }

  protected initialize(): void {}
  protected onCriteriaModified(fieldValue: any, criteria: XpoFilterCriteria): void {
    this.selection = fieldValue;
  }
}
