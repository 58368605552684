import { ChangeDetectionStrategy, Component, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GroupCellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/groupCellRenderer';

@Component({
  selector: 'grid-description',
  templateUrl: './grid-description.component.html',
  styleUrls: ['./grid-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'lau-GridCellDescription',
  },
})
export class GridDescriptionComponent {
  params: GroupCellRendererParams;
  description = new UntypedFormControl();

  @ViewChildren('descriptionInput') descriptionInput;

  agInit(params) {
    if (params.disabled) {
      this.description.disable();
    }
    this.params = params;
    this.description.setValue(params.value);
  }

  afterGuiAttached(params) {
    window.setTimeout(() => {
      this.descriptionInput.first.nativeElement.focus();
    });
  }

  getValue(): any {
    return this.description.value;
  }

  onFocusOut(e) {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }
}
