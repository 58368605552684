<div class="lau-Occurrences" (focusout)="onFocusOut($event)">
  <input
    #minInput
    numbersOnly
    type="text"
    class="lau-OccurrencesInput lau-OccurrencesInput--min"
    aria-label="Number"
    matInput
    [formControl]="min"
    tabindex="-1"
    (keydown.shift.tab)="$event.preventDefault()"
    (keydown.Tab)="onMinTabEvent($event)"
  />
  <input
    #maxInput
    numbersOnly
    type="text"
    class="lau-OccurrencesInput lau-OccurrencesInput--max"
    aria-label="Number"
    matInput
    [formControl]="max"
    tabindex="-1"
    (keydown.shift.tab)="$event.preventDefault()"
    (keydown.tab)="onMaxTabEvent($event)"
  />
</div>
