<ul *ngIf="breadcrumbs.length > 0" class="lau-BreadcrumbsList">
  <ng-container *ngFor="let route of breadcrumbs; index as i; first as isFirst">
    <li *ngIf="isFirst" class="lau-BreadcrumbItem">
      <a class="breadcrumb-home" href="" [routerLink]="[route.url]">
        <mat-icon aria-label="Home">home</mat-icon>
      </a>
      <mat-icon class="lau-BreadcrumbSeparator">chevron_right</mat-icon>
    </li>
    <li *ngIf="!isFirst" class="lau-BreadcrumbItem" [ngClass]="{ last: route.terminal }">
      <a href="" [routerLink]="[route.url]">{{ route.displayName | async }}</a>
      <mat-icon class="lau-BreadcrumbSeparator">chevron_right</mat-icon>
    </li>
  </ng-container>
</ul>
