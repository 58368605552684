import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { XpoBusyLoaderModule } from '@xpo-ltl/ngx-ltl-core/busy-loader';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core/empty-state';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { ContractAttributesDialogComponent } from '../arsenal-components/modules/features/components/contract-details/req-resp-list/dialogs/contract-attributes-dialog/contract-attributes-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { ArsenalConfirmDialogComponent } from './arsenal-confirm-dialog/arsenal-confirm-dialog.component';
import { AttributesListDialogComponent } from './attributes-list-dialog/attributes-list-dialog.component';
import { ComponentDetailDialogComponent } from './component-detail-dialog/component-detail-dialog.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { PreviewFeatureDialogComponent } from './preview-feature-dialog/preview-feature-dialog.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
  ArsenalConfirmDialogComponent,
  AttributesListDialogComponent,
  ContractAttributesDialogComponent,
  ComponentDetailDialogComponent,
  PreviewFeatureDialogComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoCardModule,
    XpoDialogModule,
    MatInputModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    XpoSnackBarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    BrowserModule,
    XpoBusyLoaderModule,
    FormsModule,
    MatSelectModule,
    DragDropModule,
    XpoTabsModule,
    XpoEmptyStateModule,
    SharedModule,
  ],
  exports: [...dialogs],
})
export class DialogModule {}
