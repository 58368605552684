<ng-container *ngIf="contract">
  <div class="contract-header-container">
    <span
      class="method"
      [ngClass]="{
        'method-get': (contract?.contractImplementation)[0]?.apiMethod === 'GET' && !noChangesState,
        'method-post': (contract?.contractImplementation)[0]?.apiMethod === 'POST' && !noChangesState,
        'method-put': (contract?.contractImplementation)[0]?.apiMethod === 'PUT' && !noChangesState,
        'method-delete': (contract?.contractImplementation)[0]?.apiMethod === 'DELETE' && !noChangesState,
        'method-disabled': noChangesState
      }"
      >{{ (contract?.contractImplementation)[0]?.apiMethod }} </span
    >&nbsp; {{ (this.contract?.contractImplementation)[0]?.uriPrefix }}
    &nbsp;&nbsp;
    <span class="service-name">{{ contract?.serviceName }} &nbsp;-&nbsp; {{ contract?.serviceShortDescr }}</span>
    <xpo-status-indicator
      *ngIf="noChangesState"
      statusText="No Changes"
      [isLabel]="true"
      statusColor="gray"
    ></xpo-status-indicator>
  </div>
</ng-container>
