<ng-container *ngIf="params.action; else directLink">
  <a (click)="callAction(params.action)">
    {{ params.value }}
  </a>
</ng-container>
<ng-template #directLink>
  <a *ngIf="!params.isAbsolute" [routerLink]="[params.actionUrl]">
    {{ params.text ? params.text : params.value }}
  </a>
  <a *ngIf="params.isAbsolute" [href]="params.actionUrl" target="_blank">
    {{ params.text ? params.text : params.value }}
  </a>
</ng-template>
