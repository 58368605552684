<div class="lau-Named" (focusout)="onFocusOut($event)">
  <input
    specialIsAlphaNumeric
    [regexStr]="'^[a-zA-Z 0-9]*$'"
    maxlength="60"
    tabindex="-1"
    #namedInput
    type="text"
    class="lau-NamedInput"
    matInput
    [formControl]="named"
    (keydown.space)="$event.preventDefault()"
    (keydown.shift.tab)="$event.preventDefault()"
    (keydown.shift.space)="$event.preventDefault()"
  />
</div>
