import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { XpoAdvancedSelectComponentOption, XpoAdvancedSelectTreeNodeComponent } from '@xpo-ltl/ngx-board/core';
import { SelectGroupingModel } from './select-grouping.model';

@Component({
  selector: 'xpo-select-opt-group',
  templateUrl: './select-opt-group.component.html',
  styleUrls: ['./select-opt-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-SelectOptGroup' },
})
export class XpoSelectOptGroupComponent implements AfterViewInit {
  filteredOptions: Array<XpoAdvancedSelectComponentOption | SelectGroupingModel> = [];
  isSelectAllSelected: boolean = false;
  hasSearchBar: boolean;
  selectedValue: XpoAdvancedSelectComponentOption;
  dataGroups: SelectGroupingModel[];
  isGrouping: boolean = false;

  /** Options of the select filter */
  @Input()
  get options(): XpoAdvancedSelectComponentOption[] | SelectGroupingModel[] {
    return this.optionsValue;
  }
  set options(v: XpoAdvancedSelectComponentOption[] | SelectGroupingModel[]) {
    this.optionsValue = this.toComponentOptions(v);

    this.filteredOptions = this.optionsValue;
    this.isGrouping = this.filteredOptions && this.filteredOptions[0] instanceof SelectGroupingModel;

    // Only show search-bar if there is a scroll bar in the container of the options.
    this.isGrouping ? (this.hasSearchBar = true) : (this.hasSearchBar = this.optionsValue.length > 5);
  }
  private optionsValue: XpoAdvancedSelectComponentOption[] | SelectGroupingModel[] = [];

  @Input()
  get selection(): string | string[] {
    return this.selectionValue;
  }
  set selection(value: string | string[]) {
    if (this.selectionValue !== value) {
      this.selectionValue = value;
      this.updateSelectedOptions(value);
    }
  }
  private selectionValue: string | string[];

  @Output()
  selectionChange = new EventEmitter<string | string[]>();

  @ViewChildren(XpoAdvancedSelectTreeNodeComponent) childTreeNodes: QueryList<XpoAdvancedSelectTreeNodeComponent>;

  @ViewChild('searchBox', { static: false })
  searchBox: ElementRef;

  constructor() {}

  /**
   * Code inspired by https://github.com/anas-aljabri/angular-tree-search/blob/master/projects/tree/src/lib/globals.ts
   */
  private static filterTree(
    tree: XpoAdvancedSelectComponentOption[],
    keyword: string
  ): XpoAdvancedSelectComponentOption[] {
    //  If the entry is empty string return the full tree without filtering and update the matches
    if (!keyword.length || !keyword.trim().length) {
      return tree;
    }

    return tree.map(function filterCallback(node: XpoAdvancedSelectComponentOption): XpoAdvancedSelectComponentOption {
      node.hidden = true;
      if (node.value.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) > -1) {
        node.hidden = false;
      } else if (node.children && node.children.length) {
        const mappedChildren = node.children.map(filterCallback);
        if (mappedChildren.some((x) => !x.hidden)) {
          node.hidden = false;
        }
      }
      return node;
    });
  }

  ngAfterViewInit(): void {
    if (this.searchBox) {
      this.searchBox.nativeElement.focus();
    }
  }

  filterOptions(value: string): void {
    if (this.isGrouping) {
      this.filteredOptions = this.filterOptionGrouping(value);
    } else {
      this.filteredOptions = XpoSelectOptGroupComponent.filterTree(
        this.getOptionsClone() as XpoAdvancedSelectComponentOption[],
        value
      );
    }
  }

  filterOptionGrouping(value: string): SelectGroupingModel[] {
    return (this.optionsValue as SelectGroupingModel[]).map((option) => {
      const data = XpoSelectOptGroupComponent.filterTree(
        [...(option.data as XpoAdvancedSelectComponentOption[])],
        value
      );
      return { ...option, data };
    });
  }

  checkIfGroupRegionIsDisplayed(regions: XpoAdvancedSelectComponentOption[]): boolean {
    if (regions && regions.length > 0) {
      return regions.some((region) => {
        if (region.hasOwnProperty('hidden')) {
          return region.hidden === false;
        } else {
          return true;
        }
      });
    }
    return false;
  }

  /** Updates the selected criterion of filter */
  onOptionSelected(selectedValue: XpoAdvancedSelectComponentOption): void {
    let value: any;
    this.selectedValue = selectedValue;
    value = selectedValue.value;
    this.selectionChange.emit(value);
  }

  private toComponentOptions(
    options: XpoAdvancedSelectComponentOption[] | SelectGroupingModel[]
  ): XpoAdvancedSelectComponentOption[] | SelectGroupingModel[] {
    const toComponentFilterOption = (option: XpoAdvancedSelectComponentOption): XpoAdvancedSelectComponentOption => {
      const children = option.children && option.children.length ? option.children.map(toComponentFilterOption) : [];

      return { ...option, indeterminate: false, selected: false, children };
    };

    return options && options[0] instanceof SelectGroupingModel
      ? (options as SelectGroupingModel[]).map(
          (opt) =>
            new SelectGroupingModel(
              opt.name,
              (opt.data as XpoAdvancedSelectComponentOption[]).map(toComponentFilterOption)
            )
        )
      : ((options as XpoAdvancedSelectComponentOption[]) || []).map(toComponentFilterOption);
  }

  private updateSelectedOptions(fieldValue: string | string[]): void {
    if (this.options && this.options[0] instanceof SelectGroupingModel) {
      const dataElements = [];
      this.options.forEach((opt) => opt.data.forEach((o) => dataElements.push(o)));
      this.selectedValue = dataElements.find((v) => v.value === <string>fieldValue) || undefined;
    } else {
      this.selectedValue =
        (this.options as XpoAdvancedSelectComponentOption[]).find((v) => v.value === <string>fieldValue) || undefined;
    }
  }

  private getOptionsClone(): XpoAdvancedSelectComponentOption[] | SelectGroupingModel[] {
    // Cloning it this way since Object.assign only clones shallow.
    return JSON.parse(JSON.stringify(this.options));
  }

  private setNodeVisibility(option: XpoAdvancedSelectComponentOption, hidden: boolean): void {
    option.hidden = hidden;
    option.children.forEach((x) => this.setNodeVisibility(x, hidden));
  }
}
