import { ChangeDetectionStrategy, Component, ElementRef, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GroupCellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/groupCellRenderer';

@Component({
  selector: 'grid-named',
  templateUrl: './grid-named.component.html',
  styleUrls: ['./grid-named.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'lau-GridCellNamed',
  },
})
export class GridNamedComponent {
  params: GroupCellRendererParams;
  named = new UntypedFormControl();

  @ViewChildren('namedInput') namedInput;

  agInit(params) {
    this.params = params;
    this.named.setValue(params.value);
  }

  afterGuiAttached(params) {
    window.setTimeout(() => {
      this.namedInput.first.nativeElement.focus();
    });
  }

  getValue(): any {
    return this.named.value;
  }

  onFocusOut(e) {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }
}
