<xpo-status-indicator
  statusText="{{ contract?.statusCd?.charAt(0).toUpperCase() + contract?.statusCd?.slice(1).toLowerCase() }}"
  statusColor="{{ contract?.statusCd?.toUpperCase() === 'IMPLEMENTED' ? 'green' : 'yellow' }}"
></xpo-status-indicator>
<div class="description">
  <pre class="description-text">{{ contract?.serviceDescription }}</pre>
</div>
<xpo-card class="request-container" *ngIf="!!request">
  <xpo-card-header>
    <xpo-card-title>Request</xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <div class="attribute">
      <div class="attribute-info">
        <span class="attribute-name">{{ request?.contractTypeName }}</span
        ><br />
        <span class="attribute-type">(body)</span>
      </div>
      <div class="attribute-description attribute-json" *ngIf="contractToDiff; else noDiffReq">
        <pre #diffReq></pre>
      </div>
      <ng-template #noDiffReq>
        <div class="attribute-description attribute-json">
          <pre>{{ requestJson?.data | json }}</pre>
        </div>
      </ng-template>
    </div>
  </xpo-card-content>
</xpo-card>
<xpo-card class="response-container" *ngIf="!!response">
  <xpo-card-header>
    <xpo-card-title>Response</xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <div class="attribute">
      <div class="attribute-info">
        <span class="attribute-name">{{ response?.contractTypeName }}</span
        ><br />
        <span class="attribute-type">(response)</span>
      </div>
      <div class="attribute-description attribute-json" *ngIf="contractToDiff; else noDiffResp">
        <pre #diffResp></pre>
      </div>
      <ng-template #noDiffResp>
        <div class="attribute-description attribute-json">
          <pre>{{ responseJson?.data | json }}</pre>
        </div>
      </ng-template>
    </div>
  </xpo-card-content>
</xpo-card>
<xpo-card class="params-container" *ngIf="hasParameters">
  <xpo-card-header>
    <xpo-card-title>Parameters</xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <div *ngFor="let type of contract.contractType">
      <div *ngIf="type.contractCategoryCd !== 'Response' && type.contractCategoryCd !== 'Request'" class="type">
        <div *ngFor="let attribute of type.contractTypeAttribute" class="attribute">
          <div class="attribute-info">
            <span class="attribute-name">{{ attribute.cntrctTypAttrName }}</span>
            <span *ngIf="convertNumber(attribute.minimumNbr) > 0" class="attribute-required"
              >&nbsp;&nbsp;* required</span
            ><br />
            <span class="attribute-data">{{
              attribute.parentType ? attribute.parentType.typeName : attribute.dataType
            }}</span
            ><br />
            <span class="attribute-type">({{ type.contractCategoryCd.toLowerCase() }})</span>
          </div>
          <div class="attribute-description">
            {{ attribute.description }}
          </div>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </xpo-card-content>
</xpo-card>
